var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{attrs:{"elevation":"1","outlined":"","loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('item.items'),"icon":"mdi-inbox-multiple"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"mx-1",attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.itemDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("item.item"))+" ")],1),_c('v-btn',{staticClass:"mx-1",attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.groupDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("fees.group"))+" ")],1)]},proxy:true}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.itemDialog),callback:function ($$v) {_vm.itemDialog=$$v},expression:"itemDialog"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary darken-1"}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("item.item")))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","rules":_vm.rules,"label":_vm.$t('item.itemName')},model:{value:(
                                                    _vm.editedItem.itemName
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "itemName", $$v)},expression:"\n                                                    editedItem.itemName\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('item.company')},model:{value:(_vm.editedItem.company),callback:function ($$v) {_vm.$set(_vm.editedItem, "company", $$v)},expression:"editedItem.company"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('item.description')},model:{value:(
                                                    _vm.editedItem.description
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"\n                                                    editedItem.description\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","dense":"","rules":_vm.rules,"persistent-hint":"","items":_vm.itemTypes,"item-text":"itemTypeName","item-value":"itemTypeId","label":_vm.$t('item.itemType')},model:{value:(
                                                    _vm.editedItem.itemTypeId
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "itemTypeId", $$v)},expression:"\n                                                    editedItem.itemTypeId\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","rules":_vm.rules,"dense":"","persistent-hint":"","items":_vm.itemsTreeList,"item-text":"itemName","item-value":"itemGuid","label":_vm.$t('item.parentItem')},model:{value:(
                                                    _vm.editedItem.parentGuid
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "parentGuid", $$v)},expression:"\n                                                    editedItem.parentGuid\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","readonly":"","dense":"","rules":_vm.rules,"label":_vm.$t('item.itemCode')},model:{value:(
                                                    _vm.editedItem.itemCode
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "itemCode", $$v)},expression:"\n                                                    editedItem.itemCode\n                                                "}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"top":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","rotate":""},on:{"click":_vm.generateCode}},'v-icon',
                                                                    attrs
                                                                ,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "accountTree.autoCode" )))])])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","dense":"","rules":_vm.rules,"persistent-hint":"","items":_vm.currencies,"item-text":"currencyName","item-value":"currencyGuid","label":_vm.$t('currency.currency')},on:{"change":function($event){return _vm.currencyChange()}},model:{value:(
                                                    _vm.editedItem.currencyGuid
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "currencyGuid", $$v)},expression:"\n                                                    editedItem.currencyGuid\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","type":"number","min":"0","rules":_vm.rules,"label":_vm.$t(
                                                        'voucher.currencyValue'
                                                    )},model:{value:(
                                                    _vm.editedItem.currencyValue
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "currencyValue", $$v)},expression:"\n                                                    editedItem.currencyValue\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t('item.price1')},model:{value:(_vm.editedItem.price1),callback:function ($$v) {_vm.$set(_vm.editedItem, "price1", $$v)},expression:"editedItem.price1"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t('item.price2')},model:{value:(_vm.editedItem.price2),callback:function ($$v) {_vm.$set(_vm.editedItem, "price2", $$v)},expression:"editedItem.price2"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t('item.price3')},model:{value:(_vm.editedItem.price3),callback:function ($$v) {_vm.$set(_vm.editedItem, "price3", $$v)},expression:"editedItem.price3"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.rules,"hide-details":"","label":_vm.$t('item.barcode1')},model:{value:(
                                                    _vm.editedItem.barcode1
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "barcode1", $$v)},expression:"\n                                                    editedItem.barcode1\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('item.barcode2')},model:{value:(
                                                    _vm.editedItem.barcode2
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "barcode2", $$v)},expression:"\n                                                    editedItem.barcode2\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('item.barcode3')},model:{value:(
                                                    _vm.editedItem.barcode3
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "barcode3", $$v)},expression:"\n                                                    editedItem.barcode3\n                                                "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.groupDialog),callback:function ($$v) {_vm.groupDialog=$$v},expression:"groupDialog"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary darken-1"}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("fees.group")))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","rules":_vm.rules,"dense":"","persistent-hint":"","items":_vm.itemsTreeList,"item-text":"itemName","item-value":"itemGuid","label":_vm.$t('item.parentItem')},model:{value:(
                                                    _vm.editedItem.parentGuid
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "parentGuid", $$v)},expression:"\n                                                    editedItem.parentGuid\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","readonly":"","dense":"","rules":_vm.rules,"label":_vm.$t('item.itemCode')},model:{value:(
                                                    _vm.editedItem.itemCode
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "itemCode", $$v)},expression:"\n                                                    editedItem.itemCode\n                                                "}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"top":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","rotate":""},on:{"click":_vm.generateCode}},'v-icon',
                                                                    attrs
                                                                ,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "accountTree.autoCode" )))])])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t('item.itemName')},model:{value:(
                                                    _vm.editedItem.itemName
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "itemName", $$v)},expression:"\n                                                    editedItem.itemName\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","label":_vm.$t('item.description')},model:{value:(
                                                    _vm.editedItem.description
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"\n                                                    editedItem.description\n                                                "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pa-5",attrs:{"outlined":"","dense":"","label":_vm.$t('search'),"flat":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.itemsTree)?_c('v-treeview',{ref:"treeReference",attrs:{"active":_vm.active,"items":_vm.itemsTree,"open":_vm.open,"search":_vm.search,"open-on-click":"","activatable":"","rounded":"","item-text":"itemName","item-key":"itemGuid","item-children":"children"},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                                                var item = ref.item;
return [_c('v-row',{on:{"click":function($event){return _vm.showDetail(item)}}},[_c('v-icon',{staticClass:"ml-3 mr-3"},[_vm._v(" "+_vm._s(item.isGroup == true ? "mdi-format-list-group" : "mdi-tag-outline")+" ")]),_c('v-col',{attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(item.itemCode)+" - "+_vm._s(item.itemName)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(
                                                item.isGroup && _vm.isInRole(59)
                                            )?_c('v-list-item',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("item.item")))])],1)],1):_vm._e(),(
                                                item.isGroup && _vm.isInRole(59)
                                            )?_c('v-list-item',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addGroup(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("item.isGroup")))])],1)],1):_vm._e(),(_vm.isInRole(59))?_c('v-list-item',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("edit")))])],1)],1):_vm._e(),(_vm.isInRole(60))?_c('v-list-item',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete-outline ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("delete")))])],1)],1):_vm._e()],1)],1)],1)],1)]}}],null,false,3817217300)}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',[_c('v-toolbar',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',attrs:{"color":_vm.$vuetify.theme.dark ? '#252e38' : '#ebebeb',"elevation":"0"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("item.info")))])],1),[_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.selectedItem),function(item,key,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(_vm.$t("item." + key)))]),_c('td',[_vm._v(_vm._s(item))])])}),0)]},proxy:true}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }