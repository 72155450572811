<template>
    <v-container fluid>
        <v-row>
            <!--Tree-->
            <v-col cols="12" md="7">
                <v-card elevation="1" outlined :loading="loading">
                    <Page-Header
                        :title="$t('item.items')"
                        icon="mdi-inbox-multiple"
                    >
                        <template v-slot:buttons>
                            <v-btn
                                :loading="loading"
                                color="primary darken-1"
                                class="mx-1"
                                @click.native="itemDialog = true"
                            >
                                <v-icon>mdi-plus</v-icon>
                                {{ $t("item.item") }}
                            </v-btn>
                            <v-btn
                                class="mx-1"
                                :loading="loading"
                                color="primary darken-1"
                                @click.native="groupDialog = true"
                            >
                                <v-icon>mdi-plus</v-icon>
                                {{ $t("fees.group") }}
                            </v-btn>
                        </template>
                    </Page-Header>
                    <!--Save item popup-->
                    <v-dialog
                        v-model="itemDialog"
                        persistent
                        max-width="1000px"
                    >
                        <v-toolbar dark color="primary darken-1">
                            <v-card-title>
                                <span>{{
                                    (editedIndex === -1
                                        ? $t("add")
                                        : $t("edit")) +
                                        " " +
                                        $t("item.item")
                                }}</span>
                            </v-card-title>
                        </v-toolbar>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    :rules="rules"
                                                    v-model="
                                                        editedItem.itemName
                                                    "
                                                    :label="$t('item.itemName')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="editedItem.company"
                                                    :label="$t('item.company')"
                                                ></v-text-field>
                                            </v-col>
                                            <!---------------------------------->
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="
                                                        editedItem.description
                                                    "
                                                    :label="
                                                        $t('item.description')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :rules="rules"
                                                    persistent-hint
                                                    v-model="
                                                        editedItem.itemTypeId
                                                    "
                                                    :items="itemTypes"
                                                    item-text="itemTypeName"
                                                    item-value="itemTypeId"
                                                    :label="$t('item.itemType')"
                                                ></v-select>
                                            </v-col>
                                            <!---------------------------------->
                                            <v-col cols="12" md="6">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    :rules="rules"
                                                    dense
                                                    persistent-hint
                                                    v-model="
                                                        editedItem.parentGuid
                                                    "
                                                    :items="itemsTreeList"
                                                    item-text="itemName"
                                                    item-value="itemGuid"
                                                    :label="
                                                        $t('item.parentItem')
                                                    "
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    dense
                                                    :rules="rules"
                                                    v-model="
                                                        editedItem.itemCode
                                                    "
                                                    :label="$t('item.itemCode')"
                                                >
                                                    <template slot="append">
                                                        <v-tooltip
                                                            top
                                                            transition="fab-transition"
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    color="primary"
                                                                    rotate
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                    @click="
                                                                        generateCode
                                                                    "
                                                                >
                                                                    mdi-refresh
                                                                </v-icon>
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    "accountTree.autoCode"
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <!---------------------------------->
                                            <v-col cols="12" md="6">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    dense
                                                    @change="currencyChange()"
                                                    :rules="rules"
                                                    persistent-hint
                                                    v-model="
                                                        editedItem.currencyGuid
                                                    "
                                                    :items="currencies"
                                                    item-text="currencyName"
                                                    item-value="currencyGuid"
                                                    :label="
                                                        $t('currency.currency')
                                                    "
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    dense
                                                    type="number"
                                                    min="0"
                                                    :rules="rules"
                                                    v-model="
                                                        editedItem.currencyValue
                                                    "
                                                    :label="
                                                        $t(
                                                            'voucher.currencyValue'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <!---------------------------------->
                                            <v-col cols="12" md="4">
                                                <v-text-field
                                                    type="number"
                                                    min="0"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    v-model="editedItem.price1"
                                                    :rules="rules"
                                                    :label="$t('item.price1')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-text-field
                                                    type="number"
                                                    min="0"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    v-model="editedItem.price2"
                                                    :rules="rules"
                                                    :label="$t('item.price2')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-text-field
                                                    type="number"
                                                    min="0"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    v-model="editedItem.price3"
                                                    :rules="rules"
                                                    :label="$t('item.price3')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :rules="rules"
                                                    hide-details
                                                    v-model="
                                                        editedItem.barcode1
                                                    "
                                                    :label="$t('item.barcode1')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="
                                                        editedItem.barcode2
                                                    "
                                                    :label="$t('item.barcode2')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="
                                                        editedItem.barcode3
                                                    "
                                                    :label="$t('item.barcode3')"
                                                ></v-text-field>
                                            </v-col>
                                            <!---------------------------------->
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1 white--text"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="red" @click="close">
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <!--Save group popup-->
                    <v-dialog
                        v-model="groupDialog"
                        persistent
                        max-width="1000px"
                    >
                        <v-toolbar dark color="primary darken-1">
                            <v-card-title>
                                <span>{{
                                    (editedIndex === -1
                                        ? $t("add")
                                        : $t("edit")) +
                                        " " +
                                        $t("fees.group")
                                }}</span>
                            </v-card-title>
                        </v-toolbar>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    :rules="rules"
                                                    dense
                                                    persistent-hint
                                                    v-model="
                                                        editedItem.parentGuid
                                                    "
                                                    :items="itemsTreeList"
                                                    item-text="itemName"
                                                    item-value="itemGuid"
                                                    :label="
                                                        $t('item.parentItem')
                                                    "
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    dense
                                                    :rules="rules"
                                                    v-model="
                                                        editedItem.itemCode
                                                    "
                                                    :label="$t('item.itemCode')"
                                                >
                                                    <template slot="append">
                                                        <v-tooltip
                                                            top
                                                            transition="fab-transition"
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    color="primary"
                                                                    rotate
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                    @click="
                                                                        generateCode
                                                                    "
                                                                >
                                                                    mdi-refresh
                                                                </v-icon>
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    "accountTree.autoCode"
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :rules="rules"
                                                    v-model="
                                                        editedItem.itemName
                                                    "
                                                    :label="$t('item.itemName')"
                                                ></v-text-field>
                                            </v-col>
                                            <!---------------------------------->
                                            <v-col cols="12" md="12">
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    dense
                                                    v-model="
                                                        editedItem.description
                                                    "
                                                    :label="
                                                        $t('item.description')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1 white--text"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="red" @click="close">
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <!--Delete popup-->
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                    <!--Search-->
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="search"
                                class="pa-5"
                                :label="$t('search')"
                                flat
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                                append-icon="mdi-magnify"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--TreeView-->
                    <v-treeview
                        v-if="itemsTree"
                        ref="treeReference"
                        :active.sync="active"
                        :items="itemsTree"
                        :open.sync="open"
                        :search="search"
                        open-on-click
                        activatable
                        rounded
                        item-text="itemName"
                        item-key="itemGuid"
                        item-children="children"
                    >
                        <template v-slot:label="{ item }">
                            <v-row @click="showDetail(item)">
                                <v-icon class="ml-3 mr-3">
                                    {{
                                        item.isGroup == true
                                            ? "mdi-format-list-group"
                                            : "mdi-tag-outline"
                                    }}
                                </v-icon>
                                <v-col align-self="center">
                                    {{ item.itemCode }} - {{ item.itemName }}
                                </v-col>
                                <v-col cols="auto" class="">
                                    <v-menu bottom left>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon
                                                    >mdi-dots-vertical</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-if="
                                                    item.isGroup && isInRole(59)
                                                "
                                            >
                                                <v-btn
                                                    text
                                                    @click="addItem(item)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon
                                                            small
                                                            class="mx-1"
                                                        >
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{
                                                        $t("item.item")
                                                    }}</v-list-item-title>
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item
                                                v-if="
                                                    item.isGroup && isInRole(59)
                                                "
                                            >
                                                <v-btn
                                                    text
                                                    @click="addGroup(item)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon
                                                            small
                                                            class="mx-1"
                                                        >
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{
                                                        $t("item.isGroup")
                                                    }}</v-list-item-title>
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item v-if="isInRole(59)">
                                                <v-btn
                                                    text
                                                    @click="editItem(item)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon
                                                            small
                                                            class="mx-1"
                                                        >
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{
                                                        $t("edit")
                                                    }}</v-list-item-title>
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item v-if="isInRole(60)">
                                                <v-btn
                                                    text
                                                    @click="deleteItem(item)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon
                                                            small
                                                            color="red"
                                                            class="mx-1"
                                                        >
                                                            mdi-delete-outline
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{
                                                        $t("delete")
                                                    }}</v-list-item-title>
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </template>
                    </v-treeview>
                </v-card>
            </v-col>
            <!--Details-->
            <v-col cols="12" md="5">
                <v-card>
                    <v-toolbar
                        :color="$vuetify.theme.dark ? '#252e38' : '#ebebeb'"
                        :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                        "
                        elevation="0"
                    >
                        <v-toolbar-title>
                            {{ $t("item.info") }}</v-toolbar-title
                        >
                    </v-toolbar>
                    <template>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                        v-for="(item, key, i) in selectedItem"
                                        :key="i"
                                    >
                                        <td>{{ $t("item." + key) }}</td>
                                        <td>{{ item }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
//import mainData from "../../Data/MainData.js";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            active: [],
            selectedItem: null,
            open: [],
            valid: true,
            loading: true,
            itemDialog:
                this.$route.query.showDialog != undefined
                    ? this.$route.query.showDialog
                    : false,
            groupDialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            branches: [],
            itemsTreeList: [],
            itemsTree: [],
            currencies: [],
            itemTypes: [],
            editedItem: {
                itemGuid: null,
                parentGuid: null,
                itemCode: null,
                itemName: null,
                description: null,
                company: null,
                price1: 0,
                price2: 0,
                price3: 0,
                minimumPrice: null,
                maximumPrice: null,
                percentage1: null,
                percentage2: null,
                percentage3: null,
                barcode1: null,
                barcode2: null,
                barcode3: null,
                costPrice: null,
                lastBuyPrice: null,
                currencyGuid: null,
                currencyValue: null,
                isGroup: false,
                itemTypeId: null
            },
            defaultItem: {
                itemGuid: null,
                parentGuid: null,
                itemCode: null,
                itemName: null,
                description: null,
                company: null,
                price1: 0,
                price2: 0,
                price3: 0,
                minimumPrice: null,
                maximumPrice: null,
                percentage1: null,
                percentage2: null,
                percentage3: null,
                barcode1: null,
                barcode2: null,
                barcode3: null,
                costPrice: null,
                lastBuyPrice: null,
                currencyGuid: null,
                currencyValue: null,
                isGroup: false,
                itemTypeId: null
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(43)) {
            this.redirectUnauthorizedUsers();
        }

        if (!this.isInRole(58)) this.redirectUnauthorizedUsers();
        this.loading = true;
        this.getData();
    },
    watch: {
        itemDialog(val) {
            val || this.close();

            this.itemsTreeList = this.itemsTreeList.filter(function(obj) {
                return obj.itemGuid !== "00000000-0000-0000-0000-000000000000";
            });
        },
        groupDialog(val) {
            val || this.close();

            var general = {
                itemGuid: "00000000-0000-0000-0000-000000000000",
                itemName: this.$t("item.mainItem")
            };
            this.itemsTreeList.push(general);
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        "editedItem.parentGuid": {
            handler: function(val) {
                if (
                    val == "00000000-0000-0000-0000-000000000000" &&
                    this.editedIndex == -1 &&
                    this.itemDialog == true
                )
                    this.$toast.error(
                        this.$t("error.ParentItemMustBeBranchGroup")
                    );
                else if (val && this.editedIndex == -1) this.generateCode();
            },
            deep: true
        },
        selectedItem: {
            handler: function() {
                this.selectedItem.createdDate = moment(
                    this.selectedItem.createdDate
                ).format("yyyy-MM-DD");

                this.selectedItem.modifiedDate = moment(
                    this.selectedItem.modifiedDate
                ).format("yyyy-MM-DD");
            },
            deep: true
        }
    },
    methods: {
        showDetail(val) {
            var item = null;
            item = Object.assign({}, val);
            delete item.itemGuid;
            delete item.parentGuid;
            delete item.parent;
            delete item.currencyGuid;
            delete item.currency;
            delete item.itemType;
            delete item.createdUserGuid;
            delete item.modifiedUserGuid;
            delete item.modifiedUser;
            delete item.createdUser;
            delete item.branchGuid;
            delete item.branch;
            delete item.serialNumber;
            delete item.isGroup;
            delete item.children;
            delete item.maximumPrice;
            delete item.minimumPrice;
            delete item.percentage1;
            delete item.percentage2;
            delete item.percentage3;
            delete item.itemTypeId;

            if (val.isGroup) {
                delete item.price;
                delete item.costPrice;
                delete item.lastBuyPrice;
                delete item.barcode;
                delete item.itemTypeId;
                delete item.company;

                delete item.price1;
                delete item.price2;
                delete item.price3;

                delete item.percentage1;
                delete item.percentage2;
                delete item.percentage3;

                delete item.barcode1;
                delete item.barcode2;
                delete item.barcode3;

                delete item.currencyValue;

                this.selectedItem = item;
            } else this.selectedItem = item;
        },
        generateCode() {
            if (
                this.editedItem.parentGuid == undefined ||
                this.editedItem.parentGuid == null ||
                this.editedItem.parentGuid == ""
            ) {
                this.$toast.error(this.$t("error.SelectParentGroup"));
            } else {
                axios
                    .get("Items/GenerateCode?id=" + this.editedItem.parentGuid)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.editedItem.itemCode = response.data.data;
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
            }
        },
        addItem(item) {
            if (item) {
                this.editedItem.parentGuid = item.itemGuid;
                if (item.itemGuid) this.generateCode();
            }
            setTimeout(() => {
                this.itemDialog = true;
            }, 100);
        },
        addGroup(item) {
            if (item) {
                this.editedItem.parentGuid = item.itemGuid;
                if (item.itemGuid) this.generateCode();
            }
            setTimeout(() => {
                this.groupDialog = true;
            }, 100);
        },
        editItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);

            if (this.editedItem.isGroup) this.groupDialog = true;
            else this.itemDialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var Item = this.branches[this.editedIndex];
            axios
                .delete("Items/Delete?id=" + Item.itemGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.itemDialog = false;
            this.groupDialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                if (this.currencies.length > 0)
                    var mainCurrency = this.currencies.find(f => f.isMain);
                if (mainCurrency != null) {
                    this.editedItem.currencyGuid = mainCurrency.currencyGuid;
                    this.editedItem.currencyValue = mainCurrency.currencyValue;
                }
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        currencyChange() {
            if (
                this.editedItem.currencyGuid != undefined &&
                this.editedItem.currencyGuid != null &&
                this.currencies != null &&
                this.currencies != undefined
            ) {
                var currencyGuid = this.editedItem.currencyGuid;

                var x = this.currencies.filter(function(obj) {
                    return obj.currencyGuid == currencyGuid;
                });

                if (x[0] != null || x[0] != undefined) {
                    this.editedItem.currencyValue = x[0].currencyValue;
                }
            }
        },
        getData() {
            this.loading = true;
            axios
                .get("Items/Get")
                .then(response => {
                    this.itemsTree = [];
                    this.itemsTreeList = [];
                    this.branches = response.data.data;

                    var general = {
                        itemGuid: "00000000-0000-0000-0000-000000000000",
                        itemName: this.$t("item.mainItem")
                    };
                    this.itemsTreeList.push(general);

                    for (var i = 0; i < this.branches.length; i++) {
                        var tree = this.branches[i];

                        var children = this.branches.filter(f => {
                            return f.parentGuid == tree.itemGuid;
                        });

                        if (children.length > 0) {
                            children.forEach(child => {
                                var childNode = {
                                    ...tree,
                                    item: child,
                                    vnode: null
                                };
                                this.$refs.treeReference.nodes[
                                    child.itemGuid
                                ] = childNode;
                            });
                            tree.children = children;
                        }

                        if (tree.isGroup) this.itemsTreeList.push(tree);

                        if (
                            this.branches[i].parentGuid ==
                            "00000000-0000-0000-0000-000000000000"
                        ) {
                            this.itemsTree.push(tree);
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                    if (this.currencies.length > 0)
                        var mainCurrency = this.currencies.find(f => f.isMain);
                    if (mainCurrency != null) {
                        this.editedItem.currencyGuid =
                            mainCurrency.currencyGuid;
                        this.editedItem.currencyValue =
                            mainCurrency.currencyValue;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

            this.loading = true;
            axios
                .get("Items/GetItemTypes")
                .then(response => {
                    this.itemTypes = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            if (this.$refs.form.validate()) {
                //check if create or edit entity
                if (this.editedIndex == -1) {
                    this.editedItem.itemGuid =
                        "00000000-0000-0000-0000-000000000000";
                }

                //check if the new item is group or item
                //if group must set some properties to default values
                if (this.groupDialog && this.editedIndex == -1) {
                    this.editedItem.isGroup = true;

                    this.editedItem.barcode1 = "0";
                    this.editedItem.barcode2 = "0";
                    this.editedItem.barcode3 = "0";

                    this.editedItem.price1 = 0;
                    this.editedItem.price2 = 0;
                    this.editedItem.price3 = 0;

                    this.editedItem.minimumPrice = 0;
                    this.editedItem.maximumPrice = 0;

                    this.editedItem.costPrice = 0;
                    this.editedItem.lastBuyPrice = 0;

                    this.editedItem.currencyValue = 0.0;

                    this.editedItem.itemTypeId = this.itemTypes[0].itemTypeId;

                    this.editedItem.percentage1 = 0;
                    this.editedItem.percentage2 = 0;
                    this.editedItem.percentage3 = 0;

                    this.editedItem.currencyGuid =
                        "00000000-0000-0000-0000-000000000000";
                }

                //check if the parent guid is not selected
                if (
                    (this.editedItem.parentGuid == undefined ||
                        this.editedItem.parentGuid == null) &&
                    this.editedItem.isGroup
                ) {
                    this.$toast.error(this.$t("error.SelectParentGroup"));
                }

                //check if item parent guid is the main group
                if (
                    this.editedItem.parentGuid ==
                        "00000000-0000-0000-0000-000000000000" &&
                    this.editedItem.isGroup == false
                ) {
                    this.$toast.error(
                        this.$t("error.ParentItemMustBeBranchGroup")
                    );
                    return;
                }

                //should be removed later
                this.editedItem.costPrice = 0;
                this.editedItem.lastBuyPrice = 0;
                this.editedItem.percentage1 = 0;
                this.editedItem.percentage2 = 0;
                this.editedItem.percentage3 = 0;
                this.editedItem.minimumPrice = 0;
                this.editedItem.maximumPrice = 0;

                axios
                    .post("Items/Save", this.editedItem)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.close();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );

                        this.getData();
                        console.log(e);
                    });
            }
        }
    }
};
</script>
